import PropTypes from 'prop-types'
import styled from 'styled-components'


const StyledPeopleContainer = styled.div`
  div:nth-child(even) {
    & > :first-child {
      & > :first-child {
        order: 1;
        margin-right: 0;
        margin-left: 1rem;
      }
    }
  }
`

const PeopleContainer = ({children}) => {
  return (
    <StyledPeopleContainer>
      {children}
    </StyledPeopleContainer>
  )
}

PeopleContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PeopleContainer
