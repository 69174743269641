import {Fragment} from 'react'
import PropTypes from 'prop-types'
import {graphql, Link} from 'gatsby'
import {If, For} from 'babel-plugin-jsx-control-statements'
import {isEmpty} from 'lodash-es'
import Appshell from '../components/Appshell'
import GatsbyImage from '../components/GatsbyImage'
import Background from '../uikit/Background'
import Button from '../uikit/Button'
import Text from '../uikit/Text'
import Flex from '../uikit/Flex'
import PeopleContainer from '../uikit/PeopleContainer'
import Person from '../uikit/Person'


const Community = ({data}) => {
  const {frontmatter, ...pageData} = data?.markdownRemark
  return (
    <Appshell>
      <Background
          desktopBackground={frontmatter?.desktopBackground?.publicURL}
          mobileBackground={frontmatter?.mobileBackground?.publicURL}
      >
        <Flex column>
          <Text component="h1" textStyle="h1Alternate">{frontmatter?.title}</Text>
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{__html: pageData?.html}} />

          <If condition={!isEmpty(frontmatter?.references)}>
            <For each="reference" of={frontmatter?.references} index="idx">
              <PeopleContainer key={idx}>
                <Person
                    name={reference?.name}
                    occupation={reference?.occupation}
                    avatar={
                      <GatsbyImage
                          image={reference?.avatar?.childImageSharp?.gatsbyImageData}
                          alt={reference?.avatarAlt}
                      />
                    }
                >
                  {reference?.description}
                </Person>
              </PeopleContainer>
            </For>
          </If>

          <If condition={!isEmpty(frontmatter?.subscribers?.list)}>
            <Text component="h2">{frontmatter?.subscribers?.title}</Text>
            <p>{frontmatter?.subscribers?.description}</p>
            <Flex column>
              <For each="subscriber" of={frontmatter?.subscribers?.list} index="idx">
                <Fragment key={idx}>
                  <p><strong>{subscriber?.name}</strong></p>
                  <ul>
                    <For each="person" of={subscriber?.people} index="idx">
                      <li key={idx}>{person}</li>
                    </For>
                  </ul>
                </Fragment>
              </For>
            </Flex>
          </If>
          <Flex mainAxis="center">
            <Button component={Link} isLink to="/">
              späť na hlavnú stránku
            </Button>
          </Flex>
        </Flex>
      </Background>
    </Appshell>
  )
}

export const data = graphql`
  query Community($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        desktopBackground {
          publicURL
        }
        mobileBackground {
          publicURL
        }
        references {
          name
          occupation
          description
          avatarAlt
          avatar {
            childImageSharp {
              gatsbyImageData(aspectRatio: 1, placeholder: BLURRED)
            }
          }
        }
        subscribers {
          description
          title
          list {
            name
            people
          }
        }
        seo {
          metaTitle
          metaDescription
          metaImage {
            publicURL
          }
        }
      }
    }
  }
`

Community.propTypes = {
  data: PropTypes.object,
}

export default Community
