import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import media from '../utils/media'
import * as typography from './theme/typography'


const StyledPersonContainer = styled.div`
  padding-top: 2rem;
`

const StyledPerson = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 3rem 1.5rem;
  ${media.up('mobile')(css`
    justify-content: start;
  `)}
`

const StyledAvatar = styled.div`
  border-radius: 50%;
  isolation: isolate;
  max-width: 6rem;
  max-height: 6rem;
  overflow: hidden;
`
const StyledHeader = styled.div`
  margin-left: 1rem;
`
const StyledName = styled.span`
  display: block;
  ${typography.h3};
`

const StyledText = styled.p`
  ${media.up('mobile')(css`
    max-width: 50%;
  `)}
`

const Person = ({name, occupation, avatar, children, ...props}) => {
  return (
    <StyledPersonContainer {...props}>
      <StyledPerson>
        <StyledAvatar>
          {avatar}
        </StyledAvatar>
        <StyledHeader>
          <StyledName>
            {name}
          </StyledName>
          <hr />
          <span>
            {occupation}
          </span>
        </StyledHeader>
      </StyledPerson>
      <StyledText>
        {children}
      </StyledText>
    </StyledPersonContainer>
  )
}

Person.propTypes = {
  name: PropTypes.string,
  occupation: PropTypes.string,
  avatar: PropTypes.node,
  children: PropTypes.node,
}

export default Person
