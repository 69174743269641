import PropTypes from 'prop-types'
import {If} from 'babel-plugin-jsx-control-statements'
import styled, {css} from 'styled-components'
import {keys} from 'lodash-es'
import triangle from '../assets/icons/triangle.svg'
import theme from './theme/theme'
import * as typography from './theme/typography'

// Explicitly list for webpack
export const styles = {
  body: typography.body,
  h1: typography.h1,
  h1Alternate: typography.h1Alternate,
  h2: typography.h2,
}

const StyledIcon = styled(triangle)`
  margin-right: 0.75rem;
  width: 0.75rem;
  height: 0.75rem;
  vertical-align: middle;

  path,
  g {
    fill: ${({theme}) => theme.colors.primary};
  }
`

const StyledSpan = styled.span`
  ${({icon}) => icon && css`
    display: flex;
    align-items: center;
  `}
  text-align: ${({align}) => align || 'inherit'};
  ${({noWrap}) => noWrap && css`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `}
  ${({textStyle}) => textStyle && styles[textStyle]}
  ${({theme, color}) => color && css`
    color: ${theme.colors[color]};
    fill: ${theme.colors[color]};
  `}
`

const Text = ({component, textStyle, color, align, noWrap, icon, children, ...props}) => {
  return (
    <StyledSpan
        as={component}
        textStyle={textStyle}
        color={color}
        align={align}
        noWrap={noWrap}
        icon={icon || textStyle === 'h1Alternate'}
        {...props}
    >
      <If condition={textStyle === 'h1Alternate'}>
        <StyledIcon />
      </If>
      {children}
    </StyledSpan>
  )
}

Text.defaultProps = {
  component: 'span',
  textStyle: 'body',
}

Text.propTypes = {
  color: PropTypes.oneOf(keys(theme.colors)),
  textStyle: PropTypes.oneOf(keys(styles)),
  align: PropTypes.oneOf(['left', 'right', 'center', 'justify']),
  noWrap: PropTypes.bool,
  icon: PropTypes.bool,
  component: PropTypes.elementType,
  children: PropTypes.node,
}


export default Text
